import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'

import useStaticModule from '@hooks/useStaticModule'
import FaqList from '@components/faqList'
import Stage from '@components/stage'
import DialogTeaserCard from '@components/dialogTeaserCard'

export const frontmatter = {
  intl: {
    slug: {
      en: 'faqs',
    },
  },
}

function FaQs({ data, pageContext }) {
  const intl = useIntl()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const stage = {
    headline: getStaticFieldValue('faqs.stage', `faqs.stage.headline`),
  }
  const bottomCard = {
    headline: getStaticFieldValue(
      'faqs.bottomcard',
      `faqs.bottomcard.headline`
    ),
    copy: getStaticFieldValue('faqs.bottomcard', `faqs.bottomcard.copy`),
  }

  const {
    faqCategoryList: { faqCategories: categories = [] },
  } = data

  const categoriesMapped = categories
    .filter((cat) => !!cat.title)
    .map((cat) => {
      const mappedFaQs = cat.faQs
        .filter((item) => !!item.question)
        .map((item) => {
          return {
            id: item.contentful_id,
            question: item.question,
            answer: item.answer?.json?.content,
          }
        })
      return {
        headline: cat.title,
        faqs: mappedFaQs,
      }
    })

  return (
    <React.Fragment>
      <FAQJsonLd
        questions={categoriesMapped.map((faq) => ({
          question: faq.question,
          answer: faq.answer,
        }))}
      />
      <Stage title={stage.headline} alignTop={true} type={'generic'}
            ariaLabel={stage.headline}
      />
      <FaqList categories={categoriesMapped} />

      {bottomCard && intl.locale === 'de' && (
        <DialogTeaserCard
          headline={bottomCard.headline}
          copy={bottomCard.copy}
          buttons={[
            {
              label: intl.formatMessage({ id: 'button.onlinedialog' }),
              link: intl.formatMessage({ id: 'dialog.path' }),
            },
            {
              label: intl.formatMessage({ id: 'button.contact' }),
              link: intl.formatMessage({ id: 'contact.path' }),
              color: 'outline',
            },
          ]}
        />
      )}
    </React.Fragment>
  )
}

FaQs.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default FaQs

export const pageQuery = graphql`
  query FaQsPageQuery($locale: String!) {
    faqCategoryList: contentfulFaqCategoryList(
      node_locale: { eq: $locale }
      identifier: { eq: "faqs" }
    ) {
      title
      faqCategories {
        title
        identifier
        id
        faQs {
          id
          contentful_id
          question
          answer {
            json
          }
        }
      }
    }
  }
`
