import React from 'react'
import PropTypes from 'prop-types'

import ToC from '@components/toc'
import Container from '@objects/container'
import FaqAccordion from '@objects/faqAccordion'

function FaqList({ categories }) {
  const tocItems = categories.map((category) => {
    return {
      id: category.headline.replace(/\s+/g, '').toLowerCase(),
      label: category.headline,
    }
  })

  function renderCategories() {
    return categories.map((category, i) => {
      return (
        <FaqAccordion
          key={i}
          headline={category.headline}
          id={category.headline.replace(/\s+/g, '').toLowerCase()}
          faqs={category.faqs}
          headlineLevel={2}
        />
      )
    })
  }

  return (
    <ToC items={tocItems} role="list">
      <Container sidebar={true} ariaLabel="arialabel.faq.toc">
        {renderCategories()}
      </Container>
    </ToC>
  )
}

FaqList.propTypes = {
  children: PropTypes.any,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      faqs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          question: PropTypes.string,
          answer: PropTypes.string,
        })
      ),
    })
  ),
}

export default FaqList
